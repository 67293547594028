import admin from './admin';
import cliqipedia from './cliqipedia';
import configure from './configure';
import enrollment from './enrollment';
import gdpr from './gdpr';
import helpdesk from './helpdesk';
import labs from './labs';
import linkedIn from './linkedIn';
import matches from './matches';
import mentorflix from './mentorflix';
import microsoft from './microsoft';
import people from './people';
import profilette from './profilette';
import program from './program';
import reports from './reports';
import resources from './resources';
import slack from './slack';
import user from './user';
import verify from './verify';

const privateRoutes = {
  ...gdpr,
  ...verify,
  ...mentorflix,
  ...helpdesk,
  ...enrollment,
  ...configure,
  ...user,
  ...admin,
  ...program,
  ...profilette,
  ...linkedIn,
  ...resources,
  ...reports,
  ...matches,
  ...people,
  ...cliqipedia,
  ...microsoft,
  ...labs,
  ...slack,
};

export default privateRoutes;
