import './style.scss';

import { FC, useMemo } from 'react';

import classNames from 'classnames';

import { MQLoaderSkeletonProps } from '../../types';
import { getRandomWidth } from '../helpers';

import { BASE_HEIGHT_REM } from './definitions';

const MQLoaderSkeleton: FC<MQLoaderSkeletonProps> = ({
  isLoading,
  shape,
  color,
  animationSpeed,
  opacityRange,
  height,
  width,
  widthInPercents,
  randomWidth,
  children,
  nullable = false,
}) => {
  const calculatedWidth = useMemo(() => {
    if (randomWidth) {
      return getRandomWidth(width, widthInPercents);
    }

    return widthInPercents ? `${width}%` : width;
  }, [randomWidth, width, widthInPercents]);

  const calculatedHeight = useMemo(
    () => (typeof height === 'string' ? `${parseInt(height) * BASE_HEIGHT_REM}rem` : height),
    [height],
  );

  return useMemo(() => {
    if (isLoading || (!children && !nullable)) {
      return (
        <div
          className={classNames('mq-loader-skeleton', shape, color, animationSpeed, opacityRange)}
          style={{
            height: calculatedHeight,
            width: calculatedWidth,
          }}
        />
      );
    }

    return children;
  }, [animationSpeed, calculatedHeight, calculatedWidth, children, color, isLoading, nullable, opacityRange, shape]);
};

export default MQLoaderSkeleton;
