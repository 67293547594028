import './style.scss';

import { FC, useEffect } from 'react';

import { getLinkPreviewSelector, getLinkPreviewLoadingStateSelector, linkPreviewThunk } from '@mentorcliq/storage';
import { MQImage } from '@mentorcliq/ui';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';

import MQLoader from 'modules/MQLoader';
import MQTypo from 'modules/MQTypo';

interface AppLinkPreviewProps {
  url: string;
}

const AppLinkPreview: FC<AppLinkPreviewProps> = ({ url }) => {
  const dispatch = useAppDispatch();
  const linkPreview = useAppSelector(({ links }) => getLinkPreviewSelector(links, url));
  const loadingState = useAppSelector(({ links }) => getLinkPreviewLoadingStateSelector(links));

  useEffect(() => {
    if (!linkPreview) {
      dispatch(
        linkPreviewThunk({
          url,
        }),
      );
    }
  }, [dispatch, url, linkPreview]);

  return (
    <a className="app-link-preview" href={url} target="_blank" rel="noopener noreferrer">
      {url}
      {!!linkPreview && loadingState.isPending && <MQLoader.Spinner variant="secondary" />}
      {linkPreview?.imageUrl && <MQImage width="100%" height="auto" src={linkPreview.imageUrl} alt={url} />}
      {linkPreview?.title && <MQTypo.Title type="h3">{linkPreview.title}</MQTypo.Title>}
      {linkPreview?.description && <MQTypo.Paragraph>{linkPreview.description}</MQTypo.Paragraph>}
    </a>
  );
};

export default AppLinkPreview;
