import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useAppLayout } from 'hooks/useAppLayout';

interface AppScrollOptions extends ScrollOptions {
  id?: string;
  force?: boolean;
  onScrollBottom?: boolean;
}

interface AppScrollTriggerProps {
  space?: number;
  selector?: string;
}

export interface AppScrollProps {
  isOnBottom: boolean;
  id: string | undefined;
  trigger: (data?: AppScrollTriggerProps) => void;
}

export const useAppScroll = ({
  onScrollBottom,
  id,
  force = false,
  behavior = 'auto',
}: AppScrollOptions = {}): AppScrollProps => {
  const { wrapperId } = useAppLayout();
  const [isOnBottom, setIsOnBottom] = useState(false);
  const mountedRef = useRef(force);

  useEffect(() => {
    mountedRef.current = true;
  }, []);

  const onScroll = useCallback(() => {
    const wrapper = document.getElementById(wrapperId);

    if (wrapper) {
      const rect = wrapper.getBoundingClientRect();
      if (wrapper.scrollHeight - rect.height - wrapper.scrollTop <= 1) {
        setIsOnBottom(true);
      } else {
        setIsOnBottom(false);
      }
    }
  }, [wrapperId]);

  useEffect(() => {
    if (onScrollBottom) {
      const wrapper = document.getElementById(wrapperId);
      wrapper?.addEventListener('scroll', onScroll);
      return () => {
        wrapper?.removeEventListener('scroll', onScroll);
      };
    }
  }, [onScrollBottom, onScroll, wrapperId]);

  const trigger = useCallback(
    (data?: AppScrollTriggerProps) => {
      if (mountedRef.current) {
        const wrapper = document.getElementById(wrapperId);

        if (wrapper) {
          const target = document.querySelector(data?.selector ?? `#${id}`);

          if (target) {
            const targetRect = target.getBoundingClientRect();
            const wrapperRect = wrapper.getBoundingClientRect();

            wrapper.scroll({
              top: wrapper.scrollTop + targetRect.top - wrapperRect.top - (data?.space ?? 0),
              behavior,
            });
          } else {
            wrapper.scroll({
              top: wrapper.offsetTop,
              behavior,
            });
          }
        }
      }
    },
    [behavior, wrapperId, id],
  );

  return useMemo(
    () => ({
      id,
      isOnBottom,
      trigger,
    }),
    [isOnBottom, id, trigger],
  );
};
