import {
  switchToUserThunk,
  forceLogoutThunk,
  getLogoutThunk,
  StoreAccessEnum,
  configureApi,
  checkTokenExpiredStatus,
  refreshTokenThunk,
} from '@mentorcliq/storage';
import { configureStore, isRejectedWithValue } from '@reduxjs/toolkit';

import { AppLocaleStorageKeysEnum } from 'types/global';

import { API_TIMOUT_MS, APP_ENV_CONFIGS } from 'definitions/configs';

import caches from 'helpers/caches';

import { createAuthHeaders, processError, refreshToken } from './middlewares';
import { combinedReducer } from './reducers';

const mqStorage = configureStore({
  preloadedState: {
    auth: {
      data: {
        user: caches.locale.getItem(AppLocaleStorageKeysEnum.User),
        viewer: caches.locale.getItem(AppLocaleStorageKeysEnum.Viewer),
      },
    },
    intl: {
      data: {
        locale: APP_ENV_CONFIGS.projectLocale,
        defaultLocale: APP_ENV_CONFIGS.projectLocale,
        tempLocale: caches.locale.getItem(AppLocaleStorageKeysEnum.Locale),
        baseMessages: {},
        dynamicMessages: {},
      },
    },
  },
  reducer: (state, action) => {
    if (
      switchToUserThunk.fulfilled.match(action) ||
      forceLogoutThunk.fulfilled.match(action) ||
      getLogoutThunk.fulfilled.match(action)
    ) {
      const newState = Object.keys(state).reduce((acc, key) => {
        if (state[key].access !== StoreAccessEnum.Private) {
          return {
            ...acc,
            [key]: state[key],
          };
        }
        return acc;
      }, {});
      return combinedReducer(newState || state, action);
    }

    return combinedReducer(state, action);
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(() => (next) => (action) => {
      if (isRejectedWithValue(action)) {
        return next(processError(action));
      }

      return next(action);
    }),
});

const api = configureApi({
  apiBaseUrl: APP_ENV_CONFIGS.projectApiUrl,
  apiVersion: APP_ENV_CONFIGS.projectApiVersion,
  timeout: API_TIMOUT_MS,
});

api.interceptors.request.use(async (request) => {
  const requestData = {
    user: caches.locale.getItem(AppLocaleStorageKeysEnum.User),
    headers: request.headers,
  };

  if (requestData.headers && !requestData.headers.isAuthenticationFlow) {
    if (requestData.user) {
      const expired = checkTokenExpiredStatus(requestData.user.expiresAt);

      if (expired) {
        try {
          const response = await mqStorage.dispatch(
            refreshTokenThunk({
              userName: requestData.user.userName,
              refreshToken: requestData.user.refreshToken,
            }),
          );

          if (refreshTokenThunk.fulfilled.match(response)) {
            requestData.user = refreshToken(requestData.user, response.payload);
          }
        } catch (e) {
          mqStorage.dispatch(forceLogoutThunk());
        }
      }

      const authHeaders = createAuthHeaders(requestData.user.accessToken);

      return {
        ...request,
        headers: {
          ...requestData.headers,
          ...authHeaders,
        },
      };
    }
  }

  return request;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      mqStorage.dispatch(forceLogoutThunk());
    }

    return Promise.reject(error);
  },
);

const storage = {
  mq: mqStorage,
  reducer: combinedReducer,
  api,
};

export default storage;
