import { FC } from 'react';

import classNames from 'classnames';

import { MQPageItemProps } from '../../types';

const MQPaginationNext: FC<MQPageItemProps> = ({ className = '', disabled, onClick, children }) => (
  <span
    role="listitem"
    tabIndex={disabled ? -1 : 0}
    data-testid="mq-pagination-next"
    className={classNames(['mq-pagination-next', { disabled }, className])}
    onClick={onClick}
  >
    <span className="pagination-item">{children}</span>
  </span>
);

export default MQPaginationNext;
