import { FC } from 'react';

import { MQImage } from '@mentorcliq/ui';
import classNames from 'classnames';

import { APP_GLOBAL_MESSAGES } from 'definitions/messages';

import { useAppIntl } from 'hooks/useAppIntl';

import MQAvatar from 'modules/MQAvatar';
import MQIcon from 'modules/MQIcon';
import MQNav from 'modules/MQNav';

import AppFormattedMessage from 'formatters/AppFormattedMessage';

import AppLink from 'router/AppLink';

interface PreviewLogoProps {
  src: string;
  branded: boolean;
}

interface PreviewProps {
  title: string;
  logo: PreviewLogoProps;
  toggle: boolean;
  onToggle: (toggle: boolean) => void;
  expended: boolean;
  onExpend: (expended: boolean) => void;
}

const Preview: FC<PreviewProps> = ({ logo, title, toggle, expended, onExpend, onToggle }) => {
  const intl = useAppIntl();

  return (
    <div className="app-header">
      <div className="app-header__section">
        <MQNav.Navbar
          className="app-header__navbar"
          expand="lg"
          onToggle={onToggle}
          onExpended={onExpend}
          ariaLabel="Nav Bar Preview"
        >
          <MQNav.Brand
            className={classNames('app-header__brand', {
              branded: logo.branded,
            })}
          >
            <MQNav.Link as={AppLink} to="#" className="app-header__logo">
              <MQImage src={logo.src} data-testid="header-logo" alt={title} />
            </MQNav.Link>
          </MQNav.Brand>

          <MQNav className="app-header__navbar-menu">
            {expended && (
              <MQNav.Toggle data-testid="app-header-toggle" className="app-header__toggle">
                {toggle ? (
                  <MQIcon.Svg aria-label="Close Menu" className="app-header-toggle__close" size="2x" icon="times-alt" />
                ) : (
                  <MQIcon.Svg aria-label="Open Menu" className="app-header-toggle__open" size="2x" icon="bars" />
                )}
              </MQNav.Toggle>
            )}

            <MQNav.Collapse data-testid="app-header-collapse" className={'app-header__navbar-collapse'}>
              <MQNav>
                <MQNav.Link as={AppLink} to="#" className="app-header__navbar-link" data-testid="app-header-my-cliq">
                  <AppFormattedMessage {...APP_GLOBAL_MESSAGES.appHeaderMyCliQ} />
                </MQNav.Link>
                <MQNav.Dropdown variant="secondary" data-testid="app-header-learning-lab-dropdown" triggers={['hover']}>
                  <MQNav.DropdownToggle data-testid="app-header-learning-lab-tooggle">
                    <MQNav.Link
                      as={AppLink}
                      to="#"
                      className="app-header__navbar-link"
                      data-testid="app-header-learning-lab-link"
                      endIcon={<MQIcon.Svg icon="caret-down" />}
                    >
                      <AppFormattedMessage {...APP_GLOBAL_MESSAGES.appHeaderMyCliQ} />
                    </MQNav.Link>
                  </MQNav.DropdownToggle>

                  <MQNav.DropdownMenu>
                    <MQNav.DropdownItem className="app-header__profile__menu-item">
                      <AppFormattedMessage {...APP_GLOBAL_MESSAGES.appHeaderMentorLab} />
                    </MQNav.DropdownItem>
                    <MQNav.DropdownItem className="app-header__profile__menu-item">
                      <AppFormattedMessage {...APP_GLOBAL_MESSAGES.appHeaderInclusionLab} />
                    </MQNav.DropdownItem>
                    <MQNav.DropdownItem className="app-header__profile__menu-item">
                      <AppFormattedMessage {...APP_GLOBAL_MESSAGES.appHeaderLeadershipLab} />
                    </MQNav.DropdownItem>
                  </MQNav.DropdownMenu>
                </MQNav.Dropdown>
                <MQNav.Link
                  as={AppLink}
                  eventKey="resource-library"
                  to="#"
                  className="app-header__navbar-link"
                  data-testid="app-header-resource-library"
                >
                  <AppFormattedMessage {...APP_GLOBAL_MESSAGES.appHeaderResourceLibrary} />
                </MQNav.Link>
              </MQNav>
            </MQNav.Collapse>
          </MQNav>

          <MQNav.Group>
            <MQNav.Dropdown className="app-header__profile" triggers={['click']}>
              <MQNav.DropdownToggle data-testid="app-header-profile-toggle" className="app-header__profile__toggle">
                <MQNav.Link
                  as={AppLink}
                  startIcon={<MQAvatar.Image size="sm" title="John Doe" dataTestId="header-profile-picture" />}
                  truncate
                  bolder
                >
                  {!expended && <strong className="app-header__profile-real-name">{'John Doe'}</strong>}
                </MQNav.Link>
              </MQNav.DropdownToggle>

              <MQNav.DropdownMenu data-testid="app-header-profile-menu" className="app-header__profile__menu">
                <MQNav.DropdownItem
                  data-testid="open-profile"
                  data-preview="disabled"
                  className="app-header__profile__menu-item"
                  startIcon={<MQIcon.Svg icon="user-circle" className="app-header__profile__menu-item-icon" />}
                >
                  <span className="app-header__profile__menu-item-title">
                    <AppFormattedMessage {...APP_GLOBAL_MESSAGES.appHeaderMyProfile} />
                  </span>
                </MQNav.DropdownItem>

                <MQNav.DropdownItem
                  data-testid="support"
                  data-preview="disabled"
                  className="app-header__profile__menu-item"
                  startIcon={<MQIcon.Svg size="lg" icon="life-ring" className="app-header__profile__menu-item-icon" />}
                >
                  <span className="app-header__profile__menu-item-title">
                    <AppFormattedMessage {...APP_GLOBAL_MESSAGES.appHeaderSupport} />
                  </span>
                </MQNav.DropdownItem>
                <MQNav.DropdownItem
                  data-testid="privacy"
                  data-preview="disabled"
                  className="app-header__profile__menu-item"
                  startIcon={<MQIcon.Svg icon="user-shield" className="app-header__profile__menu-item-icon" />}
                >
                  <span className="app-header__profile__menu-item-title">
                    <AppFormattedMessage {...APP_GLOBAL_MESSAGES.appHeaderPrivacy} />
                  </span>
                </MQNav.DropdownItem>
                <MQNav.DropdownItem
                  data-preview="disabled"
                  data-testid="sign-out"
                  className="app-header__profile__menu-item"
                  startIcon={<MQIcon.Svg icon="sign-out" className="app-header__profile__menu-item-icon" />}
                >
                  <span className="app-header__profile__menu-item-title">
                    <AppFormattedMessage {...APP_GLOBAL_MESSAGES.appHeaderLogout} />
                  </span>
                </MQNav.DropdownItem>
              </MQNav.DropdownMenu>
            </MQNav.Dropdown>

            <MQNav.Divider direction="vertical" />

            <MQNav.Link
              as={AppLink}
              data-testid="app-header-language-toggle"
              className="app-header__language"
              startIcon={<MQIcon.Svg icon="globe-light" className="app-header__language-icon" />}
            >
              <span className="app-header__language-text">{intl.defaultLocale}</span>
            </MQNav.Link>
          </MQNav.Group>
        </MQNav.Navbar>
      </div>
    </div>
  );
};

export default Preview;
