import { ParticipantMatchingStyle, RoleTypesEnum } from '@mentorcliq/storage';
import { defineMessage } from 'react-intl';

export const MATCHING_STYLES = {
  [ParticipantMatchingStyle.Group]: defineMessage({
    defaultMessage: 'Group',
    description: '[MatchingStyle] Group style',
    id: 'matching.style.group.style',
  }),
  [ParticipantMatchingStyle.OneToOne]: defineMessage({
    defaultMessage: 'One to One',
    description: '[MatchingStyle] One to one style',
    id: 'matching.style.one.to.one.style',
  }),
};

export const PROGRAM_DEFAULT_ROLES = [
  {
    id: -1,
    name: 'Mentor',
    programRoleType: RoleTypesEnum.ADVISOR,
    mostUsed: true,
  },
  {
    id: -2,
    name: 'Moderator',
    programRoleType: RoleTypesEnum.ADVISOR,
    mostUsed: false,
  },
  {
    id: -3,
    name: 'Mentee',
    programRoleType: RoleTypesEnum.ADVISEE,
    mostUsed: true,
  },
  {
    id: -4,
    name: 'Member',
    programRoleType: RoleTypesEnum.ADVISEE,
    mostUsed: false,
  },
  {
    id: -5,
    name: 'Sponsor',
    programRoleType: RoleTypesEnum.ADVISOR,
    mostUsed: false,
  },
  {
    id: -6,
    name: 'Protégé',
    programRoleType: RoleTypesEnum.ADVISEE,
    mostUsed: false,
  },
];

export const ROLE_TYPES_CONFIGS = {
  [RoleTypesEnum.ADVISOR]: {
    name: defineMessage({
      defaultMessage: 'Advisor',
      description: '[RoleTypeConfigs] Advisor name',
      id: 'role.type.advisor.name',
    }),
    labelTitle: defineMessage({
      defaultMessage: 'Advisor Role(s)',
      description: '[RoleTypeConfigs] Advisor Roles title',
      id: 'role.type.advisor.roles.title',
    }),
    labelDescription: defineMessage({
      defaultMessage: 'e.g Mentor, Coach, Sponsor, Group Leader.',
      description: '[RoleTypeConfigs] Advisor label description',
      id: 'role.type.advisor.label.description',
    }),
    addRoleLabel: defineMessage({
      defaultMessage: 'Add Advisor Role',
      description: '[RoleTypeConfigs] Add Advisor label',
      id: 'role.type.add.advisor.label',
    }),
    newRolePlaceHolder: defineMessage({
      defaultMessage: 'e. g. Advisor',
      description: '[RoleTypeConfigs] Advisor placeholder',
      id: 'role.type.advisor.placeholder',
    }),
  },
  [RoleTypesEnum.ADVISEE]: {
    name: defineMessage({
      defaultMessage: 'Advisee',
      description: '[Role Type]',
      id: 'role.type.advisee',
    }),
    labelTitle: defineMessage({
      defaultMessage: 'Advisee Role(s)',
      description: '[RoleTypeConfigs] Advisee Roles title',
      id: 'role.type.advisee.roles.title',
    }),
    labelDescription: defineMessage({
      defaultMessage: 'e.g Mentee, Coachee, Protégé, Group Member.',
      description: '[RoleTypeConfigs] Advisee label description',
      id: 'role.type.advisee.label.description',
    }),
    addRoleLabel: defineMessage({
      defaultMessage: 'Add Advisee Role',
      description: '[RoleTypeConfigs] Add Advisee label',
      id: 'role.type.add.advisee.label',
    }),
    newRolePlaceHolder: defineMessage({
      defaultMessage: 'e. g. Advisee',
      description: '[RoleTypeConfigs] Advisee placeholder',
      id: 'role.type.advisee.placeholder',
    }),
  },
  [RoleTypesEnum.PEER]: {
    name: defineMessage({
      defaultMessage: 'Peer',
      description: '[Role Type]',
      id: 'role.type.peer',
    }),
    labelTitle: defineMessage({
      defaultMessage: 'Peer Role(s)',
      description: '[RoleTypeConfigs] Peer Roles title',
      id: 'role.type.peer.roles.title',
    }),
    labelDescription: defineMessage({
      defaultMessage: 'e.g. Participant, Colleague.',
      description: '[RoleTypeConfigs] Peer label description',
      id: 'role.type.peer.label.description',
    }),
    addRoleLabel: defineMessage({
      defaultMessage: 'Add Peer Role',
      description: '[RoleTypeConfigs] Add Peer label',
      id: 'role.type.add.peer.label',
    }),
    newRolePlaceHolder: defineMessage({
      defaultMessage: 'e. g. Peer',
      description: '[RoleTypeConfigs] Peer placeholder',
      id: 'role.type.peer.placeholder',
    }),
  },
};
