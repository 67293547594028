import { FC, useEffect, useState } from 'react';

import MQIcon from 'modules/MQIcon';
import MQPagination from 'modules/MQPagination';
import paginate from 'modules/MQPagination/helpers';

import { PageableProps } from './types';

const MQPageable: FC<PageableProps> = ({ pageSize, onPaginate, totalElements, loading, currentPage, ...props }) => {
  const [state, setState] = useState(paginate(totalElements, currentPage, pageSize));

  useEffect(() => {
    setState(paginate(totalElements, currentPage, pageSize));
  }, [totalElements, currentPage, pageSize]);

  const handlePaginate = (value: number) => {
    if (value === state.currentPage) return;
    setState(paginate(totalElements, value, pageSize));
    onPaginate(value);
  };

  return (
    <MQPagination {...props}>
      <MQPagination.First
        disabled={loading || state.currentPage === state.firstPage}
        onClick={() => handlePaginate(state.firstPage)}
      >
        <MQIcon.Svg icon="angle-double-left" />
      </MQPagination.First>

      <MQPagination.Prev
        disabled={loading || state.currentPage === state.prevPage}
        onClick={() => handlePaginate(state.prevPage)}
      >
        <MQIcon.Svg icon="angle-left" />
      </MQPagination.Prev>

      {state.startPage > state.firstPage && <MQPagination.Ellipsis disabled />}

      {state.pages.map((pageNumber) => (
        <MQPagination.Item
          disabled={pageNumber !== state.currentPage && loading}
          active={pageNumber === state.currentPage}
          onClick={() => handlePaginate(pageNumber)}
          key={pageNumber}
        >
          {pageNumber}
        </MQPagination.Item>
      ))}

      {state.endPage < state.lastPage && <MQPagination.Ellipsis disabled />}

      <MQPagination.Next
        disabled={loading || state.currentPage === state.nextPage}
        onClick={() => handlePaginate(state.nextPage)}
      >
        <MQIcon.Svg icon="angle-right" />
      </MQPagination.Next>

      <MQPagination.Last
        disabled={loading || state.currentPage === state.lastPage}
        onClick={() => handlePaginate(state.lastPage)}
      >
        <MQIcon.Svg icon="angle-double-right" />
      </MQPagination.Last>
    </MQPagination>
  );
};

export default MQPageable;
