import { FC } from 'react';

import classNames from 'classnames';

import { MQPageItemProps } from '../../types';

const MQPaginationFirst: FC<MQPageItemProps> = ({
  className = '',
  disabled,
  onClick,
  ariaLabel = 'Pagination Item',
  children,
}) => (
  <span
    role="listitem"
    aria-label={ariaLabel}
    tabIndex={disabled ? -1 : 0}
    data-testid="mq-pagination-first"
    className={classNames(['mq-pagination-first', { disabled }, className])}
    onClick={onClick}
  >
    <span className="pagination-item">{children}</span>
  </span>
);

export default MQPaginationFirst;
