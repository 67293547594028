import { FC, useEffect, useState } from 'react';

import {
  bulkMatchRunningTaskThunk,
  FeatureTypeEnum,
  getBulkMatchSelector,
  getDraftProgramByProgramIdSelector,
  getProgramSelector,
  getProgramThunk,
  getSmartMatchRunningTaskThunk,
  getSmartMatchTaskIdSelector,
  ParticipantMatchingStyle,
  PermissionsEnum,
  ProgramDraftStepKeysEnum,
  programsLoadingStateSelector,
} from '@mentorcliq/storage';
import { MQImage } from '@mentorcliq/ui';
import { FormattedMessage } from 'react-intl';

import ROUTES from 'routes';

import { APP_GLOBAL_MESSAGES } from 'definitions/messages';

import { sortRolesByType } from 'helpers/programs';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppParams } from 'hooks/useAppParams';
import { useAppRouter } from 'hooks/useAppRouter';
import { useAppSelector } from 'hooks/useAppSelector';

import MQIcon from 'modules/MQIcon';
import MQLoader from 'modules/MQLoader';
import MQSidebar from 'modules/MQSidebar';

import AppDynamicMessage from 'formatters/AppDynamicMessage';
import AppFormattedMessage from 'formatters/AppFormattedMessage';

import AppLink from 'router/AppLink';

import PermissionWrapper from 'components/PermissionWrapper';

const SECTIONS_ROOT_PATHS_AND_KEYS = {
  [ROUTES.programDashboard.data.path]: [],
  [ROUTES.launch.data.path]: ['launch'],
  [ROUTES.match.data.path]: ['match'],
  [ROUTES.engage.data.path]: ['engage'],
  [ROUTES.measure.data.path]: ['measure'],
  [ROUTES.programSettings.data.path]: ['settings'],
  [ROUTES.programWizardDraft.data.path]: ['settings'],
};

const SMART_MATCH_PATHS = [
  ROUTES.viewParticipants.data.path,
  ROUTES.adminMatches.data.path,
  ROUTES.intelligentMatching.data.path,
  ROUTES.manageMatches.data.path,
];

const Program: FC = () => {
  const { programId } = useAppParams();
  const { route } = useAppRouter();
  const dispatch = useAppDispatch();
  const program = useAppSelector(({ programs }) => getProgramSelector(programs, +programId));
  const programsLoadingState = useAppSelector(({ programs }) => programsLoadingStateSelector(programs));
  const smartMatchTaskId = useAppSelector(({ smartMatch }) => getSmartMatchTaskIdSelector(smartMatch));
  const bulkMatchSubmitResponse = useAppSelector(({ bulkMatch }) => getBulkMatchSelector(bulkMatch));
  const draftProgram = useAppSelector(({ draftPrograms }) =>
    getDraftProgramByProgramIdSelector(draftPrograms, +programId),
  );

  const [selectedKeys, setSelectedKeys] = useState(
    Object.entries(SECTIONS_ROOT_PATHS_AND_KEYS).flatMap(([key, paths]) => {
      if (route.data.path.includes(key)) {
        return paths;
      }

      return [];
    }),
  );

  useEffect(() => {
    dispatch(
      getProgramThunk({
        id: +programId,
      }),
    );
  }, [dispatch, programId]);

  useEffect(() => {
    if (SMART_MATCH_PATHS.includes(route.data.path)) {
      if (program?.matchingStyle === ParticipantMatchingStyle.Group) {
        dispatch(bulkMatchRunningTaskThunk(+programId));
      } else {
        dispatch(getSmartMatchRunningTaskThunk(+programId));
      }
    }
  }, [dispatch, programId, program?.matchingStyle, route.data.path]);

  return (
    <MQSidebar.List className="app-sidebar__list" data-testid="sidebar-program">
      <MQSidebar.ListItem
        as={AppLink}
        data-testid="program-sidebar-dashboard"
        className="app-sidebar__list-item"
        to={ROUTES.adminDashboard.data.path}
        prefix={<MQIcon.Svg icon="long-arrow-alt-left" />}
      >
        <AppFormattedMessage {...APP_GLOBAL_MESSAGES.adminDashboard} />
      </MQSidebar.ListItem>

      <MQSidebar.ListItem>
        <MQSidebar.List>
          <MQSidebar.ListItem
            as={AppLink}
            data-testid="sidebar-program-info-link"
            className="app-sidebar__list-item active"
            to={ROUTES.programDashboard.convert({
              path: {
                programId: programId,
              },
            })}
            prefix={
              !!program?.programLogo?.fileHandle && (
                <MQLoader.Skeleton
                  isLoading={programsLoadingState.isPending}
                  shape="circle"
                  color="indigo"
                  animationSpeed="slow"
                  opacityRange="sm"
                  height="10x"
                >
                  <MQImage src={program.programLogo?.fileHandle} alt={program.name} contain />
                </MQLoader.Skeleton>
              )
            }
            truncate={false}
          >
            <MQLoader.Skeleton
              isLoading={programsLoadingState.isPending}
              shape="square"
              color="indigo"
              animationSpeed="slow"
              opacityRange="sm"
              height="15x"
              width={100}
              widthInPercents
            >
              {!!program && <AppDynamicMessage defaultMessage={program.name} />}
            </MQLoader.Skeleton>
          </MQSidebar.ListItem>
          <MQSidebar.ListItem>
            <MQSidebar.ListToggle
              dataTestId="program-launch"
              eventKey="launch"
              icon={<MQIcon.Svg icon="rocket-launch" />}
              title={<FormattedMessage defaultMessage="Launch" description="[Sidebar] list toggle label" />}
              activeEventKeys={selectedKeys}
              setActiveEventKeys={(keys) => setSelectedKeys(keys)}
              className="app-sidebar__list-toggle"
            >
              {program && (
                <MQSidebar.List>
                  {sortRolesByType(program.programRoles).map((role) => (
                    <MQSidebar.ListItem
                      as={AppLink}
                      data-testid={`sidebar-program-invite-role-${role.programRoleType}-link`}
                      key={role.id}
                      to={ROUTES.invitationsInvite.convert({
                        path: {
                          programId: program.id,
                          roleId: role.id,
                        },
                      })}
                      className="app-sidebar__list-item"
                    >
                      <FormattedMessage
                        defaultMessage="Invite {roleName}"
                        description="[Sidebar] Menu Item Name"
                        id="program.invite.rolename.item.name"
                        values={{
                          roleName: <AppDynamicMessage defaultMessage={role.name} />,
                        }}
                      />
                    </MQSidebar.ListItem>
                  ))}
                  {program?.programRoles.length > 1 && (
                    <MQSidebar.ListItem
                      as={AppLink}
                      data-testid="sidebar-program-invite-role-participant-link"
                      to={ROUTES.invitationsInvite.convert({
                        path: {
                          programId: program.id,
                          roleId: -1,
                        },
                      })}
                      className="app-sidebar__list-item"
                    >
                      <FormattedMessage
                        defaultMessage="Invite Participant"
                        description="[Sidebar] Menu Item Name"
                        id="program.invite.participant.item.name"
                      />
                    </MQSidebar.ListItem>
                  )}
                  <MQSidebar.ListItem
                    as={AppLink}
                    data-testid="sidebar-program-manage-invitations-link"
                    to={ROUTES.manageInvitations.convert({
                      path: {
                        programId: program.id,
                      },
                    })}
                    className="app-sidebar__list-item"
                  >
                    <FormattedMessage
                      defaultMessage="Manage Invitations"
                      description="[Program.Actions] action label"
                      id="program.manage.invitation.action.label"
                    />
                  </MQSidebar.ListItem>
                  {program?.enrollmentApprovalConfig && (
                    <MQSidebar.ListItem
                      as={AppLink}
                      data-testid="sidebar-program-view-approvals-link"
                      to={ROUTES.approvals.convert({
                        path: {
                          programId: program.id,
                        },
                      })}
                      className="app-sidebar__list-item"
                    >
                      <FormattedMessage
                        defaultMessage="View Approvals"
                        description="[Sidebar] Menu Item Name"
                        id="program.view.approvals.sidebar.item"
                      />
                    </MQSidebar.ListItem>
                  )}
                </MQSidebar.List>
              )}
            </MQSidebar.ListToggle>
          </MQSidebar.ListItem>
          <MQSidebar.ListItem>
            <MQSidebar.ListToggle
              eventKey="match"
              dataTestId="program-match"
              icon={<MQIcon.Svg icon="user-group" />}
              title={<FormattedMessage defaultMessage="Match" description="[Sidebar] list toggle label" />}
              activeEventKeys={selectedKeys}
              setActiveEventKeys={(keys) => setSelectedKeys(keys)}
              className="app-sidebar__list-toggle"
            >
              {program && (
                <MQSidebar.List>
                  <MQSidebar.ListItem
                    as={AppLink}
                    className="app-sidebar__list-item"
                    data-testid="sidebar-program-view-participants"
                    to={ROUTES.viewParticipants.convert({
                      path: {
                        programId: program.id,
                      },
                    })}
                  >
                    <FormattedMessage
                      defaultMessage="View Participants"
                      description="[Sidebar] Menu Item Name"
                      id="program.view.participants.menu.item"
                    />
                  </MQSidebar.ListItem>

                  {program?.matchingStyle === ParticipantMatchingStyle.OneToOne && (
                    <MQSidebar.ListItem
                      as={AppLink}
                      className="app-sidebar__list-item"
                      to={ROUTES.smartMatch.convert({
                        path: {
                          programId: program.id,
                        },
                      })}
                      data-testid="sidebar-program-smart-match-participants"
                      postfix={smartMatchTaskId && <MQLoader.Spinner size="sm" compact />}
                    >
                      <FormattedMessage
                        defaultMessage="Smart Match Participants"
                        description="[Sidebar] Menu Item Name"
                        id="program.smart.match.participants"
                      />
                    </MQSidebar.ListItem>
                  )}

                  {program?.matchingStyle === ParticipantMatchingStyle.Group && (
                    <MQSidebar.ListItem
                      as={AppLink}
                      className="app-sidebar__list-item"
                      to={ROUTES.groupMatch.convert({
                        path: {
                          programId: program.id,
                        },
                      })}
                      data-testid="sidebar-program-group-match-participants"
                      postfix={bulkMatchSubmitResponse && <MQLoader.Spinner size="sm" compact />}
                    >
                      <FormattedMessage
                        defaultMessage="Group Match Participants"
                        description="[Sidebar] Menu Item Name"
                        id="program.menu.item.group.match.participants"
                      />
                    </MQSidebar.ListItem>
                  )}
                  <MQSidebar.ListItem
                    as={AppLink}
                    className="app-sidebar__list-item"
                    to={ROUTES.intelligentMatching.convert({
                      path: {
                        programId: program.id,
                      },
                    })}
                    data-testid="sidebar-program-intelligent-matching"
                  >
                    <FormattedMessage
                      defaultMessage="Intelligent Matching"
                      description="[Sidebar] Menu Item Name"
                      id="program.intelligent.matching"
                    />
                  </MQSidebar.ListItem>

                  <PermissionWrapper
                    permissions={{ value: [PermissionsEnum.ManagePredefinedUnmatchReasons] }}
                    programs={{ value: [program.id] }}
                  >
                    <MQSidebar.ListItem
                      as={AppLink}
                      className="app-sidebar__list-item"
                      data-testid="sidebar-program-manage-matches-link"
                      to={ROUTES.manageMatches.convert({
                        path: {
                          programId: program.id,
                        },
                      })}
                    >
                      <FormattedMessage
                        defaultMessage="Manage Matches"
                        description="[Sidebar] Menu Item Name"
                        id="manage.matches.menu.item.name"
                      />
                    </MQSidebar.ListItem>
                  </PermissionWrapper>
                </MQSidebar.List>
              )}
            </MQSidebar.ListToggle>
          </MQSidebar.ListItem>
          <MQSidebar.ListItem>
            <MQSidebar.ListToggle
              dataTestId="program-engage"
              eventKey="engage"
              icon={<MQIcon.Svg icon="bullhorn" />}
              title={<FormattedMessage defaultMessage="Engage" description="[Sidebar] list toggle label" />}
              activeEventKeys={selectedKeys}
              setActiveEventKeys={(keys) => setSelectedKeys(keys)}
              className="app-sidebar__list-toggle"
            >
              {program && (
                <MQSidebar.List>
                  <PermissionWrapper
                    permissions={{ value: [PermissionsEnum.ManageCommunication] }}
                    programs={{ value: [program.id] }}
                  >
                    <MQSidebar.ListItem
                      as={AppLink}
                      className="app-sidebar__list-item"
                      data-testid="sidebar-program-send-communication-link"
                      to={ROUTES.sendCommunicationMain.convert({
                        path: {
                          programId: program.id,
                        },
                      })}
                    >
                      <FormattedMessage
                        defaultMessage="Send Communications"
                        description="[Sidebar] Menu Item Name"
                        id="program.send.communications.item.name"
                      />
                    </MQSidebar.ListItem>
                  </PermissionWrapper>
                  <PermissionWrapper
                    permissions={{
                      value: [PermissionsEnum.ManageParticipants, PermissionsEnum.ManagePredefinedRetireReasons],
                      strict: true,
                    }}
                    programs={{ value: [program.id] }}
                  >
                    <MQSidebar.ListItem
                      as={AppLink}
                      className="app-sidebar__list-item"
                      data-testid="sidebar-program-manage-partcipant-link"
                      to={ROUTES.manageParticipants.convert({
                        path: {
                          programId: program.id,
                        },
                      })}
                    >
                      <FormattedMessage
                        defaultMessage="Manage Participants"
                        description="[Sidebar] Menu Item Name"
                        id="program.manage.participants.item.name"
                      />
                    </MQSidebar.ListItem>
                  </PermissionWrapper>
                  <MQSidebar.ListItem
                    as={AppLink}
                    className="app-sidebar__list-item"
                    data-testid="sidebar-scheduled-communications-link"
                    to={ROUTES.programWizardDraftEdit.convert({
                      path: {
                        draftId: draftProgram?.brief.id ?? -1,
                        programId: program.id,
                      },
                      search: {
                        stepKey: ProgramDraftStepKeysEnum.ScheduledCommunications,
                      },
                    })}
                  >
                    <FormattedMessage
                      defaultMessage="Schedule Communications"
                      description="[Sidebar] Menu Item Name"
                      id="program.schedule.communications.item.name"
                    />
                  </MQSidebar.ListItem>

                  <MQSidebar.ListItem
                    as={AppLink}
                    className="app-sidebar__list-item"
                    data-testid="sidebar-program-manage-resources-link"
                    to={ROUTES.programWizardDraftEdit.convert({
                      path: {
                        draftId: draftProgram?.brief.id ?? -1,
                        programId: program.id,
                      },
                      search: {
                        stepKey: ProgramDraftStepKeysEnum.AllowProgramResources,
                      },
                    })}
                  >
                    <FormattedMessage {...APP_GLOBAL_MESSAGES.platformManageResourcesLabel} />
                  </MQSidebar.ListItem>
                  <MQSidebar.ListItem
                    as={AppLink}
                    className="app-sidebar__list-item"
                    data-testid="sidebar-survey-builder-link"
                    to={ROUTES.surveyBuilder.convert({
                      path: {
                        programId: program.id,
                      },
                    })}
                  >
                    <FormattedMessage
                      defaultMessage="Survey Builder"
                      description="[Sidebar] Menu Item Name"
                      id="program.survey.builder.item.name"
                    />
                  </MQSidebar.ListItem>
                </MQSidebar.List>
              )}
            </MQSidebar.ListToggle>
          </MQSidebar.ListItem>
          <MQSidebar.ListItem>
            <MQSidebar.ListToggle
              eventKey="measure"
              dataTestId="program-measure"
              icon={<MQIcon.Svg icon="analytics" />}
              title={<FormattedMessage defaultMessage="Measure" description="[Sidebar] list toggle label" />}
              activeEventKeys={selectedKeys}
              setActiveEventKeys={(keys) => setSelectedKeys(keys)}
              className="app-sidebar__list-toggle"
            >
              {program && (
                <MQSidebar.List>
                  <PermissionWrapper features={{ value: [FeatureTypeEnum.RoiReport] }}>
                    <MQSidebar.ListItem
                      as={AppLink}
                      className="app-sidebar__list-item"
                      data-testid="sidebar-program-roi-calculator-link"
                      to={ROUTES.programROICalculator.convert({
                        path: {
                          programId: program.id,
                        },
                      })}
                    >
                      <FormattedMessage
                        defaultMessage="ROI Calculator"
                        description="[Sidebar] Menu Item Name"
                        id="program.roi.calculator.item.name"
                      />
                    </MQSidebar.ListItem>
                  </PermissionWrapper>
                  <MQSidebar.ListItem
                    as={AppLink}
                    className="app-sidebar__list-item"
                    data-testid="sidebar-program-reports-link"
                    to={ROUTES.programReports.convert({
                      path: {
                        programId: program.id,
                      },
                    })}
                  >
                    <FormattedMessage
                      defaultMessage="Program Reports"
                      description="[Sidebar] Menu Item Name"
                      id="program.reports.menu.item.name"
                    />
                  </MQSidebar.ListItem>
                  <MQSidebar.ListItem
                    as={AppLink}
                    className="app-sidebar__list-item"
                    data-testid="sidebar-program-schedule-detailed-reports-link"
                    to={ROUTES.detailedReports.convert({
                      path: {
                        programId: program.id,
                      },
                    })}
                  >
                    <FormattedMessage
                      defaultMessage="Detailed Reports"
                      description="[Sidebar] Menu Item Name"
                      id="program.detailed.reports.item.name"
                    />
                  </MQSidebar.ListItem>
                  <MQSidebar.ListItem
                    as={AppLink}
                    className="app-sidebar__list-item"
                    data-testid="sidebar-program-export-data-link"
                    to={ROUTES.programExportData.convert({
                      path: {
                        programId: program.id,
                      },
                    })}
                  >
                    <FormattedMessage
                      defaultMessage="Export Data"
                      description="[Sidebar] Menu Item Name"
                      id="program.export.data.item.name"
                    />
                  </MQSidebar.ListItem>
                </MQSidebar.List>
              )}
            </MQSidebar.ListToggle>
          </MQSidebar.ListItem>
          <MQSidebar.ListItem>
            <MQSidebar.ListToggle
              eventKey="settings"
              dataTestId="program-settings"
              icon={<MQIcon.Svg icon="cogs" />}
              title={<AppFormattedMessage {...APP_GLOBAL_MESSAGES.programSettingsLabel} />}
              activeEventKeys={selectedKeys}
              setActiveEventKeys={(keys) => setSelectedKeys(keys)}
              className="app-sidebar__list-toggle"
            >
              {program && (
                <MQSidebar.List>
                  <PermissionWrapper
                    permissions={{ value: [PermissionsEnum.SendQuickcliQs] }}
                    programs={{ value: [program.id] }}
                  >
                    <MQSidebar.ListItem
                      as={AppLink}
                      className="app-sidebar__list-item"
                      data-testid="sidebar-program-send-quickcliQs-link"
                      to={ROUTES.programSettingsQuickcliQs.convert({
                        path: {
                          programId: program.id,
                        },
                      })}
                    >
                      <AppFormattedMessage {...APP_GLOBAL_MESSAGES.sendQuickcliQsLabel} />
                    </MQSidebar.ListItem>
                  </PermissionWrapper>

                  <PermissionWrapper permissions={{ value: [PermissionsEnum.ManageResetProfileAndEnrollments] }}>
                    <MQSidebar.ListItem
                      as={AppLink}
                      data-testid="sidebar-program-reset-participant-statuses"
                      to={ROUTES.programSettingsPromptReset.convert({
                        path: {
                          programId: program.id,
                        },
                      })}
                    >
                      <AppFormattedMessage {...APP_GLOBAL_MESSAGES.promptProfileEnrollmentUpdateLabel} />
                    </MQSidebar.ListItem>
                  </PermissionWrapper>
                  <MQSidebar.ListItem
                    as={AppLink}
                    data-testid="sidebar-program-configure-program"
                    to={ROUTES.programWizardDraftEdit.convert({
                      path: {
                        draftId: draftProgram?.brief.id ?? -1,
                        programId: program.id,
                      },
                    })}
                  >
                    <FormattedMessage
                      defaultMessage="Configure Program"
                      description="[Sidebar] Menu Item Name"
                      id="program.configure.program.item.name"
                    />
                  </MQSidebar.ListItem>
                </MQSidebar.List>
              )}
            </MQSidebar.ListToggle>
          </MQSidebar.ListItem>
        </MQSidebar.List>
      </MQSidebar.ListItem>
    </MQSidebar.List>
  );
};

export default Program;
