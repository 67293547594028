import { ValueItem } from '@mentorcliq/storage';
import { camelCase } from 'lodash';

import { APP_POINTERS, EMAIL_NAME_REGEXP, EMAILS_SEPARATOR_REGEXP } from 'definitions/configs';

export const escapeHtml = (value: string) => value.replace(/<(?:.|\n)*?>/gm, '');

export const capitalizeFirstLetter = (string: string) => {
  const str = string.toLowerCase();
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const uuid = () => {
  APP_POINTERS.uuid += 1;
  let dt = new Date().getTime();
  const id = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });

  return `${id}-${APP_POINTERS.uuid}`;
};

export const possibleValueGenerator = (value: string): ValueItem<string> => ({
  value: value,
  description: '',
  uuid: uuid(),
});

const nameEmailSeparator = (participant: string) => {
  const splitData = participant.split(' ');
  return {
    email: splitData.pop() ?? '',
    fullName: splitData.join(' ').trim(),
  };
};

export const emailsParser = (participantsString: string): { email: string; fullName?: string }[] => {
  const splitParticipants = participantsString.split(EMAILS_SEPARATOR_REGEXP).filter((email) => !!email);
  const validParticipants = splitParticipants.filter((el) => !!EMAIL_NAME_REGEXP.exec(el));

  if (splitParticipants.length === validParticipants.length && !!validParticipants.length) {
    return validParticipants.map(nameEmailSeparator);
  }

  return [];
};

export const styleParser = (styleString: string) => {
  const properties = styleString.split(';').filter(Boolean);

  return properties.reduce((acc, property) => {
    const [key, value] = property.split(':').map((el) => el.trim());

    return {
      ...acc,
      [camelCase(key)]: value,
    };
  }, {});
};

export const SHA256 = (s: string) => {
  const chrSize = 8;
  const hexCase = 0;

  const safe_add = (x: number, y: number) => {
    const lsw = (x & 0xffff) + (y & 0xffff);
    const msw = (x >> 16) + (y >> 16) + (lsw >> 16);
    return (msw << 16) | (lsw & 0xffff);
  };

  const S = (X: number, n: number) => (X >>> n) | (X << (32 - n));
  const R = (X: number, n: number) => X >>> n;

  const Ch = (x: number, y: number, z: number) => (x & y) ^ (~x & z);
  const Maj = (x: number, y: number, z: number) => (x & y) ^ (x & z) ^ (y & z);
  const Sigma0256 = (x: number) => S(x, 2) ^ S(x, 13) ^ S(x, 22);
  const Sigma1256 = (x: number) => S(x, 6) ^ S(x, 11) ^ S(x, 25);
  const Gamma0256 = (x: number) => S(x, 7) ^ S(x, 18) ^ R(x, 3);
  const Gamma1256 = (x: number) => S(x, 17) ^ S(x, 19) ^ R(x, 10);

  const core_sha256 = (m: any[], l: number) => {
    const K = [
      0x428a2f98, 0x71374491, 0xb5c0fbcf, 0xe9b5dba5, 0x3956c25b, 0x59f111f1, 0x923f82a4, 0xab1c5ed5, 0xd807aa98,
      0x12835b01, 0x243185be, 0x550c7dc3, 0x72be5d74, 0x80deb1fe, 0x9bdc06a7, 0xc19bf174, 0xe49b69c1, 0xefbe4786,
      0xfc19dc6, 0x240ca1cc, 0x2de92c6f, 0x4a7484aa, 0x5cb0a9dc, 0x76f988da, 0x983e5152, 0xa831c66d, 0xb00327c8,
      0xbf597fc7, 0xc6e00bf3, 0xd5a79147, 0x6ca6351, 0x14292967, 0x27b70a85, 0x2e1b2138, 0x4d2c6dfc, 0x53380d13,
      0x650a7354, 0x766a0abb, 0x81c2c92e, 0x92722c85, 0xa2bfe8a1, 0xa81a664b, 0xc24b8b70, 0xc76c51a3, 0xd192e819,
      0xd6990624, 0xf40e3585, 0x106aa070, 0x19a4c116, 0x1e376c08, 0x2748774c, 0x34b0bcb5, 0x391c0cb3, 0x4ed8aa4a,
      0x5b9cca4f, 0x682e6ff3, 0x748f82ee, 0x78a5636f, 0x84c87814, 0x8cc70208, 0x90befffa, 0xa4506ceb, 0xbef9a3f7,
      0xc67178f2,
    ];
    const HASH = [0x6a09e667, 0xbb67ae85, 0x3c6ef372, 0xa54ff53a, 0x510e527f, 0x9b05688c, 0x1f83d9ab, 0x5be0cd19];
    const W = new Array(64);
    let a, b, c, d, e, f, g, h;
    let T1, T2;

    m[l >> 5] |= 0x80 << (24 - (l % 32));
    m[(((l + 64) >> 9) << 4) + 15] = l;

    for (let i = 0; i < m.length; i += 16) {
      a = HASH[0];
      b = HASH[1];
      c = HASH[2];
      d = HASH[3];
      e = HASH[4];
      f = HASH[5];
      g = HASH[6];
      h = HASH[7];

      for (let j = 0; j < 64; j++) {
        if (j < 16) W[j] = m[j + i];
        else W[j] = safe_add(safe_add(safe_add(Gamma1256(W[j - 2]), W[j - 7]), Gamma0256(W[j - 15])), W[j - 16]);

        T1 = safe_add(safe_add(safe_add(safe_add(h, Sigma1256(e)), Ch(e, f, g)), K[j]), W[j]);
        T2 = safe_add(Sigma0256(a), Maj(a, b, c));

        h = g;
        g = f;
        f = e;
        e = safe_add(d, T1);
        d = c;
        c = b;
        b = a;
        a = safe_add(T1, T2);
      }

      HASH[0] = safe_add(a, HASH[0]);
      HASH[1] = safe_add(b, HASH[1]);
      HASH[2] = safe_add(c, HASH[2]);
      HASH[3] = safe_add(d, HASH[3]);
      HASH[4] = safe_add(e, HASH[4]);
      HASH[5] = safe_add(f, HASH[5]);
      HASH[6] = safe_add(g, HASH[6]);
      HASH[7] = safe_add(h, HASH[7]);
    }
    return HASH;
  };

  const str2Binb = (str: string) => {
    const bin: number[] = [];
    const mask = (1 << chrSize) - 1;
    for (let i = 0; i < str.length * chrSize; i += chrSize) {
      bin[i >> 5] |= (str.charCodeAt(i / chrSize) & mask) << (24 - (i % 32));
    }
    return bin;
  };

  const Utf8Encode = (string: string) => {
    string = string.replace(/\n/g, '\n');
    let utfText = '';

    for (let n = 0; n < string.length; n++) {
      const c = string.charCodeAt(n);

      if (c < 128) {
        utfText += String.fromCharCode(c);
      } else if (c > 127 && c < 2048) {
        utfText += String.fromCharCode((c >> 6) | 192);
        utfText += String.fromCharCode((c & 63) | 128);
      } else {
        utfText += String.fromCharCode((c >> 12) | 224);
        utfText += String.fromCharCode(((c >> 6) & 63) | 128);
        utfText += String.fromCharCode((c & 63) | 128);
      }
    }

    return utfText;
  };

  const bin2Hex = (binArray: number[]) => {
    const hex_tab = hexCase ? '0123456789ABCDEF' : '0123456789abcdef';
    let str = '';
    for (let i = 0; i < binArray.length * 4; i++) {
      str +=
        hex_tab.charAt((binArray[i >> 2] >> ((3 - (i % 4)) * 8 + 4)) & 0xf) +
        hex_tab.charAt((binArray[i >> 2] >> ((3 - (i % 4)) * 8)) & 0xf);
    }
    return str;
  };

  s = Utf8Encode(s);
  return bin2Hex(core_sha256(str2Binb(s), s.length * chrSize));
};
