import { useEffect, useState, useDebugValue, useCallback } from 'react';

import isMobile from 'ismobilejs';

const MOBILE_WIDTH = 768;

export const useAppViewport = ({ width = MOBILE_WIDTH, agent = navigator.userAgent } = {}) => {
  const [state, setState] = useState({
    isMobile: window.innerWidth <= width || isMobile(agent).phone,
    isTablet: window.innerWidth <= width || isMobile(agent).tablet,
  });

  const handleResize = useCallback(() => {
    setState({
      isMobile: global.window.innerWidth <= width || isMobile(agent).phone,
      isTablet: global.window.innerWidth <= width || isMobile(agent).tablet,
    });
  }, [agent, width]);

  useEffect(() => {
    global.window.addEventListener('resize', handleResize);

    return () => {
      global.window.removeEventListener('resize', handleResize);
    };
  }, [agent, handleResize, width]);

  useDebugValue('useAppViewport');

  return state;
};
