import { default as Ellipsis } from './components/MQPaginationEllipsis';
import { default as First } from './components/MQPaginationFirst';
import { default as Item } from './components/MQPaginationItem';
import { default as Last } from './components/MQPaginationLast';
import { default as Next } from './components/MQPaginationNext';
import { default as Prev } from './components/MQPaginationPrev';
import { default as Provider } from './components/MQPaginationProvider';
import './components/MQPaginationProvider/style.scss';

const MQPagination = Object.assign(Provider, {
  First,
  Prev,
  Ellipsis,
  Item,
  Next,
  Last,
});

export default MQPagination;
