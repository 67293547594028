import './style.scss';

import { FC, HTMLAttributes } from 'react';

import classNames from 'classnames';

export interface MQTableProps extends HTMLAttributes<HTMLTableElement> {
  striped?: boolean;
  borderless?: boolean;
  centered?: boolean;
  dataTestId?: string;
  align?: 'top' | 'middle' | 'bottom';
  alignHeader?: 'start' | 'center' | 'end';
  layout?: 'vertical' | 'horizontal-left' | 'horizontal-right';
  responsive?: boolean | string;
  stretched?: boolean;
  inline?: boolean;
  caption?: 'bottom' | 'top';
  variant?: 'sections-highlighted' | 'primary' | 'secondary';
}

const MQTableProvider: FC<MQTableProps> = ({
  className = '',
  striped = false,
  responsive = true,
  borderless = false,
  centered = false,
  stretched = false,
  inline = false,
  align = 'middle',
  alignHeader = 'start',
  layout = 'vertical',
  variant = 'primary',
  dataTestId = 'mq-table',
  ...props
}) => (
  <div
    className={classNames([
      'mq-table',
      `align-${align}`,
      `header-${alignHeader}`,
      layout,
      variant,
      { responsive },
      { centered },
      { striped },
      { inline },
      { stretched },
      { borderless },
      className,
    ])}
    {...props}
  >
    <table data-testid={dataTestId} tabIndex={0} aria-label="Table" {...props} />
  </div>
);

export default MQTableProvider;
