import { FC } from 'react';

import classNames from 'classnames';

import { MQPaginationProps } from '../../types';

const MQPaginationProvider: FC<MQPaginationProps> = ({ className, size = 'md', children }) => (
  <div className={classNames(['mq-pagination', size, className])} role="list">
    {children}
  </div>
);

export default MQPaginationProvider;
