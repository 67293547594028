import { FC } from 'react';

import classNames from 'classnames';

import { MQPageItemProps } from '../../types';

const MQPaginationEllipsis: FC<MQPageItemProps> = ({ className = '', disabled }) => (
  <span
    data-testid="mq-pagination-ellipsis"
    className={classNames(['mq-pagination-ellipsis', { disabled }, className])}
  >
    <span className="pagination-item">...</span>
  </span>
);

export default MQPaginationEllipsis;
