import {
  InvitationStyleEnum,
  ParticipantMatchingStyle,
  ProgramRequestDto,
  ProgramResponseDto,
  ProgramRoleDto,
  RoleEnrollmentInfoDto,
} from '@mentorcliq/storage';

import { ROLE_TYPES_CONFIGS } from 'definitions/programs';

export interface SortRoleByType {
  data: any;
  programRole: ProgramRoleDto;
}

export const sortRolesByType = (programRoles: ProgramRoleDto[]): ProgramRoleDto[] =>
  Object.keys(ROLE_TYPES_CONFIGS).reduce<ProgramRoleDto[]>(
    (acc, programRoleType) => acc.concat(programRoles.filter((item) => item.programRoleType === programRoleType)),
    [],
  );

export const sortEnrollmentsByType = (enrollments: RoleEnrollmentInfoDto[]): RoleEnrollmentInfoDto[] =>
  Object.keys(ROLE_TYPES_CONFIGS).reduce<RoleEnrollmentInfoDto[]>(
    (acc, programRoleType) =>
      acc.concat(enrollments.filter((item) => item.programRole.programRoleType === programRoleType)),
    [],
  );

export const sortDataByRole = (data: SortRoleByType[]) => {
  data.sort((a, b) => a.programRole.name.localeCompare(b.programRole.name));

  return Object.keys(ROLE_TYPES_CONFIGS).reduce<SortRoleByType[]>(
    (acc, programRoleType) => acc.concat(data.filter((item) => item.programRole.programRoleType === programRoleType)),
    [],
  );
};

export const sortRolesByRoleIds = <
  T extends {
    programRoleId: number;
    [key: string]: any;
  },
>(
  programRoles: ProgramRoleDto[],
  items: T[],
): T[] => {
  const roleIds = sortRolesByType(programRoles).map((role) => role.id);
  return [...items].sort((prev, next) => roleIds.indexOf(prev.programRoleId) - roleIds.indexOf(next.programRoleId));
};

export const serializeProgramResponse = (request: ProgramRequestDto): ProgramResponseDto => ({
  ...request,
  id: request.id ?? -1,
  name: request.name ?? '',
  description: request.description ?? '',
  demo: false,
  retired: false,
  programCategory: request.programCategory ?? {
    id: -1,
    name: '',
  },
  programPillar: request.programPillar ?? {
    id: -1,
    name: '',
  },
  invitationStyleConfig: request.invitationStyleConfig ?? {
    id: -1,
    invitationStyle: InvitationStyleEnum.Public,
    openStyleExcludedRoleIds: [],
  },
  relationshipLength: request.relationshipLength ?? 0,
  allowManageRelationship: !!request.allowManageRelationship,
  matchingStyle: request.matchingStyle ?? ParticipantMatchingStyle.OneToOne,
  showProgramDetails: !!request.showProgramDetails,
  programRoles: request.programRoles ?? [],
  matchConfigurations: request.matchConfigurations ?? [],
  enrollmentPreferenceConfig: request.enrollmentPreferenceConfig ?? {
    questions: [],
  },
  preferencesDisplayConfigs: request.preferencesDisplayConfigs ?? [],
  goalsEnabled: !!request.goalsEnabled,
});
