import './style.scss';

import { FC, HTMLAttributes, ReactNode } from 'react';

import classNames from 'classnames';

interface MQScrollableWrapperProps extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  className?: string;
  onScrollToBottom?: () => void;
  height?: number | `${number}`;
}

const MQScrollableWrapper: FC<MQScrollableWrapperProps> = ({
  children,
  className = '',
  height = 400,
  onScrollToBottom,
  onScroll,
}) => (
  <div
    className={classNames('mq-scrollable-wrapper', className)}
    style={{
      maxHeight: height,
    }}
    onScroll={(e) => {
      if (e.currentTarget.clientHeight + Math.ceil(e.currentTarget.scrollTop) >= e.currentTarget.scrollHeight) {
        onScrollToBottom?.();
      }

      onScroll?.(e);
    }}
  >
    {children}
  </div>
);

export default MQScrollableWrapper;
