import { FC } from 'react';

import classNames from 'classnames';

import { MQPageItemProps } from '../../types';

const MQPaginationItem: FC<MQPageItemProps> = ({ className = '', active, disabled, onClick, children }) => (
  <span
    role="listitem"
    tabIndex={disabled || active ? -1 : 0}
    data-testid="mq-pagination-item"
    className={classNames(['mq-pagination-item', { disabled }, { active }, className])}
    onClick={onClick}
  >
    <span className="pagination-item">{children}</span>
  </span>
);

export default MQPaginationItem;
