const paginate = (totalItems: number, currentPage = 1, pageSize = 10, showPages = 5) => {
  // calculate total pages
  const totalPages = Math.ceil(totalItems / pageSize);
  // ensure current page isn't out of range
  if (currentPage < 1) {
    currentPage = 1;
  } else if (currentPage > totalPages) {
    currentPage = totalPages;
  }

  let startPage: number;
  let endPage: number;

  if (totalPages <= showPages) {
    // total pages less than max so show all pages
    startPage = 1;
    endPage = totalPages;
  } else {
    // total pages more than max so calculate start and end pages
    if (currentPage <= Math.floor(showPages / 2)) {
      // current page near the start
      startPage = 1;
      endPage = showPages;
    } else if (currentPage + Math.floor(showPages / 2) >= totalPages) {
      // current page near the end
      startPage = totalPages - showPages + 1;
      endPage = totalPages;
    } else {
      // current page somewhere in the middle
      startPage = currentPage - Math.floor(showPages / 2);
      endPage = currentPage + Math.floor(showPages / 2);
    }
  }

  // create an array of pages to ng-repeat in the pager control
  const pages = Array.from(Array(endPage + 1 - startPage).keys()).map((i) => startPage + i);

  // return object with all pager properties required by the view
  return {
    totalItems: totalItems,
    currentPage: currentPage,
    pageSize: pageSize,
    nextPage: Math.min(currentPage + 1, totalPages),
    prevPage: Math.max(currentPage - 1, 1),
    firstPage: 1,
    lastPage: totalPages,
    totalPages: totalPages,
    startPage: startPage,
    endPage: endPage,
    pages: pages,
  };
};

export default paginate;
