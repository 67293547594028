import './style.scss';

import { FC, useCallback, useContext, useEffect, useRef } from 'react';

import classNames from 'classnames';

import { MQNavbarCollapseProps } from '../../types';
import { MQNavbarContext } from '../MQNavbar';

const MQNavbarCollapse: FC<MQNavbarCollapseProps> = ({
  className = '',
  id,
  uncontrolled = false,
  children,
  ...props
}) => {
  const { collapsed, expanded, setCollapsed } = useContext(MQNavbarContext);

  const containerRef = useRef<HTMLDivElement | null>(null);

  const onClickOutside = useCallback(() => {
    if (!uncontrolled) {
      setCollapsed?.(false);
    }
  }, [uncontrolled, setCollapsed]);

  useEffect(() => {
    const handleOutsideClick = () => {
      onClickOutside();
    };

    if (expanded) {
      document.addEventListener('click', handleOutsideClick);
    }

    if (!collapsed) {
      document.removeEventListener('click', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [expanded, onClickOutside, collapsed]);

  return (
    <div
      data-testid="mq-navbar-collapse"
      {...props}
      id={id}
      className={classNames(['mq-navbar__collapse collapse', 'navbar-collapse', 'collapse', className], {
        show: expanded ? collapsed : true,
        expanded,
      })}
      onClick={(e) => {
        e.stopPropagation();
        setCollapsed?.(false);
      }}
      ref={containerRef}
    >
      {children}
    </div>
  );
};

export default MQNavbarCollapse;
