import { AxiosError } from 'axios';

import ROUTES from 'routes';

export const ERRORS = {
  notAccess: {
    codes: [403],
    redirect: ROUTES.statusPage403.data.path,
  },
  notFound: {
    codes: [404],
    redirect: ROUTES.statusPage404.data.path,
  },
  tooManyRequest: {
    codes: [429],
    redirect: ROUTES.statusPage429.data.path,
  },
  internalProblem: {
    codes: [0, 500, 501, 502, 503, 504, 505, 506, 507, 508, 509, 510, 511, AxiosError.ERR_NETWORK],
    redirect: ROUTES.statusPage500.data.path,
  },
};
