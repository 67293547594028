import { FC } from 'react';

import classNames from 'classnames';

import { MQPageItemProps } from '../../types';

const MQPaginationPrev: FC<MQPageItemProps> = ({ className = '', disabled, onClick, children }) => (
  <span
    role="listitem"
    tabIndex={disabled ? -1 : 0}
    data-testid="mq-pagination-prev"
    className={classNames(['mq-pagination-prev', { disabled }, className])}
    onClick={onClick}
  >
    <span className="pagination-item">{children}</span>
  </span>
);

export default MQPaginationPrev;
