import './style.scss';

import { FC, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';

import classNames from 'classnames';
import { createPortal } from 'react-dom';

import { MQModalContext } from '../../definitions';
import { MQModalProviderProps } from '../../types';

const MQModalProvider: FC<MQModalProviderProps> = ({
  show,
  onHide,
  className = '',
  size = 'md',
  centered = true,
  scrollable = true,
  expanded = false,
  delay = 300,
  closableKeys = ['Escape'],
  hideOnBackdropClick = true,
  instance,
  children,
  blurBackground = false,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const timeOutRef = useRef<number>(-1);
  const [visible, setVisible] = useState(show);

  useEffect(() => {
    if (containerRef.current && visible) {
      if (!containerRef.current.contains(document.activeElement)) {
        containerRef.current.focus();
      }
    }
  }, [containerRef, visible]);

  const closeModal = useCallback(() => {
    setVisible(false);
    timeOutRef.current = window.setTimeout(() => {
      onHide?.();
    }, delay);
  }, [delay, onHide]);

  useEffect(() => {
    window.clearTimeout(timeOutRef.current);
    if (show) {
      setVisible(true);
    } else {
      setVisible(false);
      timeOutRef.current = window.setTimeout(() => {
        onHide?.();
      }, delay);
    }
  }, [show, delay, onHide]);

  useImperativeHandle(instance, () => ({
    close: closeModal,
  }));

  if (show) {
    return (
      <MQModalContext.Provider
        value={{
          close: closeModal,
        }}
      >
        {createPortal(
          <div
            ref={containerRef}
            style={{ animationDuration: `${delay}ms` }}
            role="dialog"
            id="modal.container"
            title="Modal Container"
            aria-describedby="modal-body"
            className={classNames('mq-modal-container', { centered, visible, expanded, blurBackground })}
            onScroll={(e) => {
              e.stopPropagation();
            }}
            onKeyDown={(e) => {
              if (closableKeys?.includes(e.key)) {
                closeModal();
              }
            }}
            onMouseDown={() => {
              if (hideOnBackdropClick) {
                closeModal();
              }
            }}
          >
            <div tabIndex={-1} className={classNames('mq-modal-dialog', size, { scrollable })}>
              <div
                data-testid="mq-modal"
                tabIndex={0}
                role="presentation"
                className={classNames(['mq-modal', className])}
                onMouseDown={(e) => {
                  e.stopPropagation();
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {children}
              </div>
            </div>
          </div>,
          document.body,
        )}
      </MQModalContext.Provider>
    );
  }

  return null;
};

export default MQModalProvider;
