import { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  addMessagesThunk,
  ChatMarkAsReadRequestDto,
  ChatMessageSubmitDto,
  ChatMessageType,
  ChatRoomDto,
  checkTokenExpiredStatus,
  FeatureTypeEnum,
  getAllChatRoomsSelector,
  getAuthUserSelector,
  getChatRoomThunk,
  readMessagesThunk,
  refreshTokenThunk,
} from '@mentorcliq/storage';
import { debounce } from 'lodash';

import { STATIC_ASSETS } from 'definitions/assets';
import { APP_DEBOUNCE_TIME } from 'definitions/configs';
import { APP_SOCKET_CONFIGS, APP_SOCKET_INITIAL_CONTEXT, AppSocketContext } from 'definitions/context';

import alerts from 'helpers/alerts';
import { uuid } from 'helpers/string';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppIntl } from 'hooks/useAppIntl';
import { useAppSelector } from 'hooks/useAppSelector';

import MQButton from 'modules/MQButton';
import MQIcon from 'modules/MQIcon';

import AppFormattedMessage from 'formatters/AppFormattedMessage';

import PermissionWrapper from 'components/PermissionWrapper';

interface SocketProviderProps {
  children?: ReactNode;
  ownerId: number;
  accessToken: string;
  expiresAt: number;
  refreshToken: string;
  userName: string;
  rooms: ChatRoomDto[];
}

const SocketProvider: FC<SocketProviderProps> = ({
  children,
  ownerId,
  accessToken,
  refreshToken,
  userName,
  expiresAt,
  rooms,
}) => {
  const intl = useAppIntl();
  const dispatch = useAppDispatch();

  const [socketConfigs, setSocketConfigs] = useState(APP_SOCKET_INITIAL_CONTEXT.socketConfigs);
  const [chatConfigs, setChatConfigs] = useState(APP_SOCKET_INITIAL_CONTEXT.chatConfigs);

  const socketRef = useRef<{
    timerId: number;
    alertId: string | null;
    instance: WebSocket | null;
    ownerId: number | null;
  }>({
    instance: null,
    timerId: 0,
    alertId: null,
    ownerId: null,
  });

  const expiresAtRef = useRef<number>(expiresAt);

  const chatRef = useRef({
    unread: 0,
    timer: 0,
    audio: new Audio(STATIC_ASSETS.audio.message),
  });

  const handleSocketMessage = useCallback(
    (message: MessageEvent<string>) => {
      const [headers, response] = message.data.replace(`\x00`, ``).split(`\n\n`);

      if (headers && response) {
        const body = JSON.parse(response);
        if (body.message || body.attachment) {
          const request = {
            message: body,
            type: body.authorBasicInfo.id === ownerId ? ChatMessageType.SEND : ChatMessageType.RECEIVED,
            match: null,
          };

          setChatConfigs((prev) => ({
            ...prev,
            messages: [body],
          }));
          dispatch(addMessagesThunk(request));
        } else if (body.readTime && body.userId === ownerId) {
          dispatch(readMessagesThunk(body));
        }
      }
    },
    [dispatch, ownerId],
  );

  const initSocket = useCallback(() => {
    socketRef.current.ownerId = ownerId;

    if (!socketRef.current?.instance) {
      setSocketConfigs((prev) => ({
        ...prev,
        attempts: prev.attempts + 1,
      }));

      socketRef.current.instance = new WebSocket(APP_SOCKET_CONFIGS.brokerUrl);

      socketRef.current.instance.onopen = () => {
        if (socketRef.current.instance) {
          setSocketConfigs((prev) => ({
            ...prev,
            attempts: 0,
            errors: [],
          }));
        }

        if (socketRef.current?.instance?.readyState === WebSocket.OPEN) {
          socketRef.current.instance.send(
            `CONNECT\naccept-version:1.1,1.0\nheart-beat:${APP_SOCKET_CONFIGS.brokerTimeout},${APP_SOCKET_CONFIGS.brokerTimeout}\ntoken:${accessToken}\n\n\u0000`,
          );

          if (socketRef.current.ownerId) {
            socketRef.current.instance?.send(
              `SUBSCRIBE\nid:${socketRef.current.ownerId}\ndestination:${APP_SOCKET_CONFIGS.chatSubscribeUrl}/${socketRef.current.ownerId}\n\n\u0000`,
            );
          }
        }

        window.clearInterval(chatRef.current.timer);

        chatRef.current.timer = window.setInterval(() => {
          if (socketRef.current?.instance?.readyState === WebSocket.OPEN) {
            socketRef.current.instance.send(`\n`);
          } else {
            window.clearInterval(chatRef.current.timer);
          }
        }, APP_SOCKET_CONFIGS.brokerTimeout);
      };

      socketRef.current.instance.onclose = (e) => {
        window.clearInterval(chatRef.current.timer);

        if (socketRef.current.instance) {
          setSocketConfigs((prev) => ({
            ...prev,
            errors: [
              {
                code: e.code,
                reason: e.reason,
              },
            ],
          }));
          socketRef.current.instance = null;
          socketRef.current.ownerId = null;
        }
      };

      socketRef.current.instance.onmessage = (message) => {
        handleSocketMessage(message);

        const expired = checkTokenExpiredStatus(expiresAtRef.current);

        if (expired) {
          dispatch(
            refreshTokenThunk({
              userName: userName,
              refreshToken: refreshToken,
            }),
          );
        }
      };
    }
  }, [ownerId, accessToken, handleSocketMessage, dispatch, userName, refreshToken]);

  useEffect(() => {
    expiresAtRef.current = expiresAt;
  }, [expiresAt]);

  useEffect(() => {
    const socket = socketRef.current;

    return () => {
      window.clearTimeout(socket.timerId);

      if (socket.instance?.readyState === WebSocket.OPEN) {
        socket.instance?.send(`UNSUBSCRIBE\nid:${socket.ownerId}\n\n\u0000`);
        socket.instance?.close();
      }

      socket.instance = null;
      socket.ownerId = null;
    };
  }, [ownerId]);

  useEffect(() => {
    initSocket();
  }, [initSocket]);

  useEffect(() => {
    if (chatConfigs.messages.length) {
      const newRoomId = chatConfigs.messages[chatConfigs.messages.length - 1].chatRoomId;
      const newRoom = rooms.find(({ id }) => id === newRoomId);

      if (!newRoom) {
        dispatch(
          getChatRoomThunk({
            roomId: newRoomId,
          }),
        );
      }
    }
  }, [dispatch, chatConfigs.messages, rooms]);

  const readDebounce = useRef(
    debounce((data) => {
      if (socketRef.current?.instance?.readyState === WebSocket.OPEN) {
        socketRef.current?.instance?.send(`SEND\ndestination:${APP_SOCKET_CONFIGS.chatReadUrl}\n\n${data}\n\u0000`);
      }
    }, APP_DEBOUNCE_TIME),
  );

  const chatRooms = useMemo(
    () =>
      rooms?.reduce(
        (acc, room) => ({
          ...acc,
          [room.match.id]: {
            ...room,
            uuid: room.uuid ?? uuid(),
            messages: room?.messages || [],
          },
        }),
        APP_SOCKET_INITIAL_CONTEXT.chatRooms,
      ),
    [rooms],
  );

  const unread = useMemo(
    () => Object.values(chatRooms || {})?.reduce((acc, item) => acc + (item.unreadMessagesCount || 0), 0),
    [chatRooms],
  );

  useEffect(() => {
    if (unread > chatRef.current.unread) {
      if (chatRef.current.audio.readyState === 4) {
        chatRef.current.audio.focus();
        chatRef.current.audio
          .play()
          .then(() => {
            chatRef.current.audio.currentTime = 0;
          })
          .catch(() => {
            chatRef.current.audio.focus();
            chatRef.current.audio.currentTime = 0;
          });

        chatRef.current.unread = unread;
      }
    }
  }, [unread]);

  useEffect(() => {
    if (socketConfigs.errors.length) {
      window.clearTimeout(socketRef.current.timerId);
      if (socketConfigs.attempts > 1) {
        socketRef.current.alertId = alerts.addWarning({
          message: intl.formatMessage({
            defaultMessage: 'Something went wrong. Please try to reload the connection.',
            description: '[Chat] reconnection warning message',
            id: 'socket.wrapper.chat.reconnect.warning.message',
          }),
          button: (
            <MQButton
              dataTestId="reconnect-socket"
              variant="warning"
              onClick={() => {
                initSocket();
              }}
              startIcon={<MQIcon.Svg icon="rotate" />}
            >
              <AppFormattedMessage
                defaultMessage="Reconnect"
                description="[Chat] reconnect button label"
                id="chat.reconnect.button.label"
              />
            </MQButton>
          ),
        });
      } else {
        socketRef.current.timerId = window.setTimeout(() => {
          initSocket();
        }, APP_SOCKET_CONFIGS.connectTimeout);
      }
    } else if (socketRef.current.alertId) {
      alerts.remove(socketRef.current.alertId);
    }
  }, [initSocket, intl, socketConfigs.attempts, socketConfigs.errors]);

  const contextValue = useMemo(
    () => ({
      socketConfigs,
      chatConfigs,
      chatRooms,
      unread,
      send: (data: ChatMessageSubmitDto) => {
        if (socketRef.current?.instance?.readyState === WebSocket.OPEN) {
          const body = JSON.stringify(data);
          socketRef.current?.instance?.send(`SEND\ndestination:${APP_SOCKET_CONFIGS.chatSubmitUrl}\n\n${body}\n\u0000`);
        }
      },
      read: (data: ChatMarkAsReadRequestDto) => {
        readDebounce.current.cancel();
        const body = JSON.stringify(data);
        readDebounce.current(body);
      },
      toggleRoom: (roomId: number) => {
        setChatConfigs((prev) => ({
          ...prev,
          active: true,
          roomId,
        }));
      },
      toggleChat: (status: boolean) => {
        setChatConfigs((prev) => ({
          ...prev,
          active: status,
        }));
      },
    }),
    [socketConfigs, chatConfigs, chatRooms, unread],
  );

  return <AppSocketContext.Provider value={contextValue}>{children}</AppSocketContext.Provider>;
};

interface SocketWrapperProps {
  children?: ReactNode;
}

const SocketWrapper: FC<SocketWrapperProps> = ({ children }) => {
  const authUser = useAppSelector(({ auth }) => getAuthUserSelector(auth));
  const chatRooms = useAppSelector(({ chat }) => getAllChatRoomsSelector(chat));

  return (
    <PermissionWrapper features={{ value: [FeatureTypeEnum.Chat] }} fallback={children}>
      {!!authUser && !!chatRooms ? (
        <SocketProvider
          rooms={chatRooms}
          ownerId={authUser.id}
          expiresAt={authUser.expiresAt}
          refreshToken={authUser.refreshToken}
          userName={authUser.userName}
          accessToken={authUser.accessToken}
        >
          {children}
        </SocketProvider>
      ) : (
        children
      )}
    </PermissionWrapper>
  );
};

export default SocketWrapper;
