import { FC, HTMLAttributes, ReactNode } from 'react';

interface MMQTableTdProps extends HTMLAttributes<HTMLTableCellElement> {
  width?: number | `${number}%`;
  children?: ReactNode;
}

export const MQTableTd: FC<MMQTableTdProps> = ({ width, children, ...props }) => (
  <td
    style={{
      width,
    }}
    {...props}
  >
    {children}
  </td>
);

export default MQTableTd;
